//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken } from '../../common/api'
import { WebView } from '../../common/app'
import EZUIKit from 'ezuikit-js'
import Utils from '../../util/index'
export default {
  data() {
    return {
      url: '', //地址
      accessToken: '', //萤石云token
      timing: 0,
      networkAnomaly: false,
      timer: null,
      isShow: false,
    }
  },
  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    let that = this
    WebView.getPidAndAccessShiP = this.getPidAndAccessShiP
    console.log('数据', this.getPidAndAccessid)
    Utils.$on('getToken', () => {
      that.getToken()
    })
  },
  methods: {
    getPidAndAccessShiP(pidAndAccessId) {
      console.log('传过来的数据', pidAndAccessId)
      // let all = pidAndAccessId.split('And')
      // const accessId = all[1]
      // const pid = all[0]
      // this.$store.commit('SET_ACCESS_ID', accessId)
      // this.$store.commit('SET_P_ID', pid)
      this.url = pidAndAccessId
      setTimeout(() => {
        this.getToken()
      }, 500)
    },

    // 视频初始化
    async getToken() {
      // 发送 POST 请求
      console.log('进来')

      const res = await getToken()
      if (res.data.code != 200) return
      this.accessToken = res.data.data.accessToken
      console.log(this.accessToken)
      //视频初始化
      console.log('数据999999', res)
      console.log(this.url, 'this.urlthis.url')
      this.player = await new EZUIKit.EZUIKitPlayer({
        autoplay: true, //自动播放
        id: 'video-container', // 视频容器ID
        accessToken: this.accessToken,
        url: this.url,
        audio: 0,
      })
      this.timer = setInterval(() => {
        this.timing += 60000
        if (this.timing >= 600000) {
          this.stopVideo()
          console.log('结束播放')
          this.networkAnomaly = true
          clearInterval(this.timer)
          return
        }
      }, 60000)
    },
    // 停止播放
    stopVideo() {
      this.player.stop()
    },
    // 退出
    tuichu() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
    close() {
      console.log('点击关闭')
      window.location.href = 'uniwebview://closeFeed?test=closeFeed'
    },
  },
}
